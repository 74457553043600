export const translations = {
  en: {
    welcome: "Welcome to Paeonia Innovations' Seminar on Mid-IR Spectrometer!",
    companyIntro:
      "Paeonia Innovations specializes in compact, online Mid-IR spectrometer for monitoring reactions and industrial applications. We deliver high-performance, online analytical tools that enhance efficiency and scientific advancements.",
    basicInfo: "Basic Information",
    productInterest: "Product and Application Interests",
    confirmation: "Confirmation",
    name: "Full Name",
    companyName: "Research Institute/University/Company Name",
    jobPosition: "Job Position",
    country: "Country/Region",
    region: "Country/Province",
    regionList: [
      "Beijing",
      "Chongqing",
      "Shanghai",
      "Tianjin",
      "Hong Kong",
      "Macau",
      "Anhui",
      "Fujian",
      "Gansu",
      "Guangdong",
      "Guizhou",
      "Hainan",
      "Hebei",
      "Heilongjiang",
      "Henan",
      "Hubei",
      "Hunan",
      "Jiangsu",
      "Jiangxi",
      "Jilin",
      "Liaoning",
      "Qinghai",
      "Shaanxi",
      "Shandong",
      "Shanxi",
      "Sichuan",
      "Yunnan",
      "Zhejiang",
      "Taiwan",
      "Guangxi Zhuang Autonomous Region",
      "Inner Mongolia Autonomous Region",
      "Ningxia Hui Autonomous Region",
      "Tibet Autonomous Region",
      "Xinjiang Uygur Autonomous Region",
      "Other Countries",
    ],
    phone: "Phone Number (Optional)",
    email: "Email",
    wechat: "WeChat ID (Optional)",
    companySize: "Research Institute/University/Company Size",
    productCategories: "Product Categories",
    productAppCategories: "Application Categories",
    requirements: "Specific Requirements",
    requirementsText: "Describe your expected product requirement",
    others: "Other Applications",
    othersText: "Describe your application scenarios",
    productCategoriesList: [
      "Mid-IR Spectrometer 900-1800 cm-1",
      "Mid-IR Spectrometer 1800-3500 cm-1",
      "Mid-IR Spectrometer 900-3500 cm-1",
    ],
    productAppCategoriesList: [
      "Continuous Flow Chemistry",
      "Reactor Design and Development",
      "Reaction/Process Monitoring",
      "Purity Monitoring",
      "Concentration Monitoring",
      "Blending Verification",
      "Complex Component Mixtures Quantification",
    ],
    timeline: "Procurement Timeline",
    timelinesList: [
      "Within 1 month",
      "Within 3 months",
      "Within 6 months",
      "6+ months",
    ],
    ReviewInfo: "Review Your Information",
    newsletter: "I agree to receive product updates and industry news",
    privacy: "I agree to provide above information",
    submit: "Submit",
    submitting: "Submitting...",
    success: "Success!",
    next: "Next",
    previous: "Previous",
    visitBooth: "Visit us at A*STAR Partners' Centre (A*PC).",
    contactSoon: "We will contact you within 3 working days!",
    required: "Please choose",
    invalidEmail: "Please enter a valid email address",
    invalidPhone: "Please enter a valid phone number",
    chemometricsModel:
      "Can you create a chemometrics model from raw IR spectra?",
    answerYes: "Yes",
    answerNo: "No",
    softwareChemometricsModel:
      "If 'Yes', what software do you use to create the chemometrics model?",
  },
  zh: {
    welcome: "欢迎莅临 Paeonia Innovations 中红外光谱仪研讨会！",
    companyIntro:
      "Paeonia Innovations 专注于先进的中红外光谱仪解决方案，应用于在线化学分析和工业。我们提供高性能的在线分析，以提升精准度、效率，并推动科学进步。",
    basicInfo: "基本信息",
    productInterest: "产品和应用兴趣",
    confirmation: "确认",
    name: "姓名",
    companyName: "研究机构/大学/公司名称",
    jobPosition: "职位",
    country: "国家/地区",
    region: "国家/地区",
    regionList: [
      "北京",
      "重庆",
      "上海",
      "天津",
      "香港",
      "澳门",
      "安徽",
      "福建",
      "甘肃",
      "广东",
      "贵州",
      "海南",
      "河北",
      "黑龙江",
      "河南",
      "湖北",
      "湖南",
      "江苏",
      "江西",
      "吉林",
      "辽宁",
      "青海",
      "陕西",
      "山东",
      "山西",
      "四川",
      "云南",
      "浙江",
      "台湾",
      "广西壮族自治区",
      "内蒙古自治区",
      "宁夏回族自治区",
      "西藏自治区",
      "新疆维吾尔自治区",
      "其他国家",
    ],
    phone: "电话号码（选填）",
    email: "电子邮箱",
    wechat: "微信号（选填）",
    companySize: "研究机构/大学/公司规模",
    productCategories: "产品类别",
    productAppCategories: "应用类别",
    requirements: "具体需求",
    requirementsText: "描述您的预期商品要求",
    others: "其他应用",
    othersText: "描述您的应用场景",
    productCategoriesList: [
      "中红外光谱仪 900-1800 cm-1",
      "中红外光谱仪 1800-3500 cm-1",
      "中红外光谱仪 900-3500 cm-1",
    ],
    productAppCategoriesList: [
      "连续流化学",
      "反应器设计与开发",
      "反应/过程监测",
      "纯度监测",
      "浓度监测",
      "混合验证",
      "复杂成分混合物定量",
    ],
    timeline: "采购时间",
    timelinesList: ["1个月内", "3个月内", "6个月内", "6个月后"],
    ReviewInfo: "查看您的信息",
    newsletter: "我同意接收产品更新和行业新闻",
    privacy: "我同意提供以上资料",
    submit: "提交",
    submitting: "提交中...",
    success: "提交成功！",
    next: "下一步",
    previous: "上一步",
    visitBooth:
      "欢迎莅临 苏州工业园区 新平街388号 腾飞创新园15幢 新科研企业合作中心.5楼.",
    contactSoon: "我们将在3个工作日内与您联系!",
    required: "请选择",
    invalidEmail: "请输入有效的电子邮箱地址",
    invalidPhone: "请输入有效的电话号码",
    chemometricsModel: "您是否能够从原始 IR 光谱创建化学计量学模型?",
    answerYes: "是",
    answerNo: "否",
    softwareChemometricsModel:
      "如果选择 '是', 您使用哪种软件来创建化学计量学模型?",
  },
};
